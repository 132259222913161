import React from "react"
import SeoData from "@content/seo/fx.json"
import Seo from "@components/seo"

// Import image
import ogpimage from '@images/kaigai-fx/ogp.png';

export function SeoContents ({ Path:pathName, Title:setTitle, Slug:setSlug, PageNum: setNum, Description: postDescription, Date:date, Modified:modified}) {

  // archiveページ用設定
  const indexUrl = 'kaigai-fx';
  const setDescription ="海外FXの「" + setTitle +"」についてまとめた一覧ページです。";

  // fx.jsonに設定した情報を取得
  let title = SeoData[ pathName + "_title" ];
  let description = SeoData[ pathName + "_description" ];
  
  // 共通設定
  const ogpSiteName = '海外FXメディア情報発信';
  const ogpImage = process.env.ORIGINURL + ogpimage;
  const ogpImageWidth = '1200';
  const ogpImageHeight = '630';
  const ogpImageType = 'image/png';
  
  // 個別設定
  let ogpUrl, publishedDate, modifiedDate ;
  if ( pathName === 'categories' || pathName === 'tags') { // アーカイブページの処理
    ogpUrl = `${process.env.ORIGINURL}/${indexUrl}/${pathName}/${setSlug}/${setNum === 1? '': `${setNum}/`}`;
    publishedDate = date;
    modifiedDate = modified;
  } else if ( pathName === 'index' ) { // トップページ
    ogpUrl = process.env.ORIGINURL + "/" + indexUrl + "/";
    if (setTitle !== undefined) { // トップ固定記事がある場合
      title = setTitle;
      description = postDescription;
      publishedDate = date;
      modifiedDate = modified;
    }
  } else if ( pathName === 'term' ) { // 用語集
    ogpUrl = process.env.ORIGINURL + "/" + indexUrl + "/" + setSlug + "/";
    title = SeoData[ setSlug + "_title" ];
    description = SeoData[ setSlug + "_description" ];
  } else { // その他ページ
    ogpUrl = process.env.ORIGINURL + "/" + indexUrl + "/" + pathName + "/";
  } 

  const seoTag =
  <Seo
    title={setTitle ? setTitle : title} // アーカイブページはsetTitleを表示、固定ページはtitleを表示
    description={setTitle ? setDescription : description} // アーカイブページはsetDescriptionを表示、固定ページはdescriptionを表示
    // ここ
    ogpUrl={ogpUrl}
    ogpSiteName={ogpSiteName}
    ogpImage={ogpImage}
    ogpImageWidth={ogpImageWidth}
    ogpImageHeight={ogpImageHeight}
    ogpImageType={ogpImageType}
    publishedDate={publishedDate}
    modifiedDate={modifiedDate}
  />
  return seoTag
}